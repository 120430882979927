import pathify from '@/plugins/vuex-pathify';
import Vue from 'vue';
import Vuex from 'vuex';
import Vapi from 'vuex-rest-api';

Vue.use(Vuex);

const avisos = new Vapi(
    {
        baseURL: process.env.VUE_APP_AVISOS_API_BASE_URL,
        state: {
            aviso: {},
        },
    })
    .post({
        action: 'postAviso',
        property: 'aviso',
        path: '/aviso',
    })
    .getStore({
        namespaced: true,
    });

const globales = new Vapi(
    {
        baseURL: process.env.VUE_APP_AVISOS_API_BASE_URL,
        state: {
            cementerios: [],
            velatorios: [],
            precios: {},
            tamanios: {},
        },
    })
    .get({
        action: 'getCementerios',
        property: 'cementerios',
        path: '/cementerios',
    })
    .get({
        action: 'getVelatorios',
        property: 'velatorios',
        path: '/velatorios',
    })
    .get({
        action: 'getPrecios',
        property: 'precios',
        path: '/config/precios',
    })
    .get({
        action: 'getTamanios',
        property: 'tamanios',
        path: '/config/tamanios',
    })
    .getStore({
        namespaced: true,
    });

const store = new Vuex.Store({
    modules: { globales, avisos },
    plugins: [
        pathify.plugin,
    ],
    strict: true,
});

export default store;
