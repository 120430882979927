<template>
  <v-app>
    <v-main class="main mx-auto">
      <v-container fluid>
        <h1 class="text-h1 text-center black--text">
          AVISOS F&Uacute;NEBRES
        </h1>

        <v-divider class="my-3" />

        <p class="my-2 secondary--text">
          Por medio de este formulario Ud. puede publicar un aviso fúnebre en El Observador. <br>
          Los avisos se recepcionan hasta las 16:30. <br>
        </p>
        <div class="mb-6 secondary--text">
          Tamaño de los avisos:
          <ul>
            <li>
              Hasta {{ tamanios['1col'] }} caracteres:
              <strong>{{ formatPrecio(precios['1col']) || '--' }} IVA inc.</strong>
            </li>
            <li>
              Entre {{ tamanios['1col'] + 1 }} y {{ tamanios['2col'] }} caracteres:
              <strong>{{ formatPrecio(precios['2col']) || '--' }} IVA inc.</strong>
            </li>
            <li>
              A partir de {{ tamanios['3col'] }} caracteres:
              <strong>{{ formatPrecio(precios['3col']) || '--' }} IVA inc.</strong>
            </li>
          </ul>
        </div>

        <v-form ref="avisoForm"
                v-model="formValid">
          <v-row>
            <v-col cols="12"
                   sm="7">
              <v-row dense>
                <v-col cols="auto">
                  <v-select v-model="aviso.tipo_aviso"
                            :items="options.TIPOS_AVISO"
                            label="Tipo de aviso">
                    <template #append-outer>
                      <v-tooltip bottom
                                 nudge-left="35">
                        <template #activator="{ on, attrs }">
                          <v-icon class="ml-2"
                                  v-bind="attrs"
                                  v-on="on">
                            mdi-help-circle
                          </v-icon>
                        </template>
                        <p class="my-0">
                          <b>Sepelios:</b> Aviso para anunciar un fallecimiento y los datos del sepelio a realizarse.
                        </p>
                        <p class="my-0">
                          <b>Participaciones:</b> Aviso para anunciar un fallecimiento y sepelio ya realizado.
                        </p>
                      </v-tooltip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="auto">
                  <v-radio-group v-model="aviso.simbolo"
                                 label="Símbolo"
                                 row
                                 mandatory
                                 dense
                                 class="legend-top">
                    <v-radio v-for="opt in options.SIMBOLOS"
                             :key="opt.value"
                             :value="opt.value"
                             class="align-center">
                      <template #label>
                        <span class="text-h4">{{ opt.unicode }}</span>
                        <span class="ml-1">{{ opt.value }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12"
                   sm="5">
              <v-img v-if="aviso.simbolo"
                     class="mx-auto"
                     :src="require(`@/assets/aviso-${aviso.simbolo.toLowerCase()}.png`)"
                     max-height="250"
                     contain />
            </v-col>
          </v-row>

          <v-divider class="hr-dotted my-1" />

          <h2 class="text-h2 text-center primary--text">
            Texto del aviso
          </h2>

          <p class="mt-1 mb-4 secondary--text">
            Por favor ingrese los datos de su aviso, con mayúsculas/minúsculas, tal como desea que sea publicado.
            No se aceptan textos todos en mayúscula. Una vez ingresados los datos, podrá editar el aviso completo
            sugerido.
          </p>

          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="aviso.nombre_difunto"
                            label="NOMBRE COMPLETO"
                            :rules="[ rules.required ]" />
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="aviso.deudos"
                          label="DEUDOS"
                          outlined />
            </v-col>
            <v-col cols="12"
                   sm="6">
              <v-menu ref="menu"
                      v-model="fechaDecesoMenu"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template #activator="{ on, attrs }">
                  <v-text-field v-model="fechaDecesoFormatted"
                                label="Fecha del deceso"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on" />
                </template>
                <v-date-picker v-model="aviso.fecha_deceso"
                               no-title
                               scrollable
                               locale="es-uy"
                               @input="fechaDecesoMenu = false" />
              </v-menu>
            </v-col>
            <v-col cols="12"
                   sm="6">
              <v-select v-model="aviso.id_cementerio"
                        :items="cementeriosOptions"
                        label="CEMENTERIO" />
            </v-col>
            <v-col cols="12"
                   sm="6">
              <v-select v-model="aviso.id_casa_velatoria"
                        :items="velatoriosOptions"
                        label="CASA VELATORIA" />
            </v-col>
            <v-col cols="12"
                   sm="6">
              <v-text-field v-model="aviso.casa_velatoria_direccion">
                <template #label>
                  DIRECCI&Oacute;N
                </template>
              </v-text-field>
            </v-col>
            <template v-if="aviso.tipo_aviso !== 'Participación'">
              <v-col cols="12"
                     sm="4">
                <v-text-field v-model="aviso.sala_velatoria"
                              label="SALA" />
              </v-col>
              <v-col cols="12"
                     sm="4">
                <v-text-field v-model="aviso.hora_velatorio"
                              label="HORA DEL VELATORIO" />
              </v-col>
              <v-col cols="12"
                     sm="4">
                <v-text-field v-model="aviso.hora_partida"
                              label="HORA DE PARTIDA DEL CORTEJO" />
              </v-col>
            </template>
            <v-col cols="12"
                   class="text-center">
              <v-btn class="my-3"
                     color="primary"
                     x-large
                     @click="generarTextoSugerido">
                Generar texto sugerido
              </v-btn>
              <v-alert v-model="generarAlert.visible"
                       border="left"
                       text
                       max-width="350"
                       type="warning"
                       class="mx-auto">
                {{ generarAlert.texto }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <div class="mb-2 d-flex align-center">
                <span class="theme--light v-label">AVISO COMPLETO</span>
                <v-tooltip bottom
                           color="black"
                           nudge-right="100"
                           max-width="450">
                  <template #activator="{ on, attrs }">
                    <v-icon class="ml-2"
                            v-bind="attrs"
                            v-on="on">
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <p class="my-0">
                    Presionando el botón Generar texto sugerido se le presentará un texto para el aviso con los datos
                    ingresados previamente. Por favor, verifique que esté todo correcto y agregue o modifique lo que
                    considere necesario antes de presionar el botón Enviar.
                  </p>
                </v-tooltip>
              </div>
              <v-textarea v-model="aviso.aviso"
                          counter
                          :counter-value="(value) => `Largo del aviso: ${(value || '').length} caracteres`"
                          hint="Este será el texto del aviso, no se debe agregar aquí el nombre de la persona fallecida."
                          :rules="[ rules.required ]"
                          outlined />
            </v-col>
            <v-col cols="12"
                   sm="5">
              <v-menu ref="menu"
                      v-model="fechaPublicacionMenu"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template #activator="{ on, attrs }">
                  <v-text-field v-model="fechaPublicacionFormatted"
                                label="FECHA DE PUBLICACIÓN DEL AVISO"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on" />
                </template>
                <v-date-picker v-model="aviso.fecha_publicacion"
                               no-title
                               scrollable
                               locale="es-uy"
                               :allowed-dates="fechaHabilitada"
                               @input="fechaPublicacionMenu = false" />
              </v-menu>
            </v-col>
            <!--<v-col cols="12"
                   sm="5"
                   align-self="center">
              Tamaño del aviso: {{ aviso.aviso ? aviso.aviso.length : '0' }} caracteres
              &lt;!&ndash;              <v-text-field label="">&ndash;&gt;
              &lt;!&ndash;              </v-text-field>&ndash;&gt;
            </v-col>-->
          </v-row>

          <v-divider class="hr-dotted my-1" />

          <h2 class="text-h2 text-center primary--text">
            Datos de facturación
          </h2>

          <v-row align="center">
            <v-col>
              <div v-if="!aviso.avisador_es_funeraria"
                   class="font-weight-bold">
                Monto: {{ formatPrecio(aviso.precio) }}
              </div>
            </v-col>
            <v-col>
              <v-radio-group v-model="aviso.avisador_tipo"
                             row
                             dense
                             mandatory>
                <v-radio v-for="opt in options.TIPOS_AVISADOR"
                         :key="opt.value"
                         :value="opt.value"
                         :label="opt.text" />
              </v-radio-group>
            </v-col>
          </v-row>

          <template v-if="aviso.avisador_tipo === 'PARTICULAR' || aviso.avisador_tipo === 'EMPRESA'">
            <v-row>
              <v-col cols="12"
                     sm="6">
                <v-text-field v-model="aviso.avisador_nombre"
                              :label="aviso.avisador_tipo === 'EMPRESA' ? 'Razón Social' : 'Nombre completo'"
                              :rules="[ rules.required ]"
                              dense />
              </v-col>
              <v-col cols="12"
                     sm="6">
                <v-text-field v-model="aviso.avisador_documento"
                              :label="aviso.avisador_tipo === 'EMPRESA' ? 'RUT' : 'C.I.'"
                              :rules="aviso.avisador_tipo === 'EMPRESA' ? [ rules.required, rules.validarRUT ] : [ rules.required ]"
                              dense />
              </v-col>
              <v-col cols="12"
                     sm="6">
                <v-text-field v-model="aviso.avisador_email"
                              label="Email"
                              :rules="[ rules.required ]"
                              dense />
              </v-col>
              <v-col cols="12"
                     sm="6">
                <v-text-field v-model="aviso.avisador_telefono"
                              label="Teléfono"
                              :rules="[ rules.required ]"
                              dense />
              </v-col>

              <v-col cols="12"
                     sm="6">
                <v-text-field v-model="aviso.avisador_direccion"
                              label="Dirección"
                              :rules="[ rules.required ]"
                              dense />
              </v-col>
              <v-col cols="12"
                     sm="6">
                <v-text-field v-model="aviso.avisador_cod_postal"
                              label="Cod. Postal"
                              :rules="[ rules.required ]"
                              dense />
              </v-col>
              <v-col cols="12"
                     sm="6">
                <v-text-field v-model="aviso.avisador_ciudad"
                              label="Ciudad"
                              :rules="[ rules.required ]"
                              dense />
              </v-col>
              <v-col cols="12"
                     sm="6">
                <v-select v-model="aviso.avisador_departamento"
                          :items="options.DEPARTAMENTOS"
                          label="Departamento"
                          dense />
              </v-col>
              <v-col cols="12"
                     sm="6">
                <v-select v-model="aviso.avisador_forma_pago"
                          :items="options.FORMAS_PAGO"
                          label="Forma de pago"
                          dense />
              </v-col>
              <v-col cols="12"
                     sm="6"
                     align-self="center">
                <!--                <p class="text&#45;&#45;secondary"-->
                <!--                   v-html="DESCRIPCION_PAGOS[aviso.avisador_forma_pago] || ''" />-->
                <div v-if="aviso.avisador_forma_pago === 'ABITAB'"
                     class="text--secondary">
                  Recibirá en la dirección especificada el talón de pago para Abitab.
                </div>
                <div v-if="aviso.avisador_forma_pago === 'TRANSFERENCIA'"
                     class="text--secondary">
                  Procedimiento para pago con transferencia bancaria:<br>
                  <ol>
                    <li>
                      Transferir el monto correspondiente a la siguiente cuenta bancaria:
                      <ul style="list-style-type: none;">
                        <li>Banco Santander</li>
                        <li>Cuenta Corriente en pesos, Nº 003078302606, agencia 0004.</li>
                        <li>DOLSUR S.A.</li>
                      </ul>
                    </li>
                    <li>
                      Enviar el comprobante de la transferencia por correo electrónico a funebres@observador.com.uy,
                      haciendo referencia al número de aviso.
                    </li>
                  </ol>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="aviso.avisador_tipo === 'FUNERARIA'">
            <v-row>
              <v-col cols="12"
                     sm="4">
                <v-text-field v-model="aviso.avisador_email"
                              label="Email"
                              :rules="[ rules.required ]"
                              dense />
              </v-col>
              <v-col cols="12"
                     sm="4">
                <v-text-field v-model="aviso.avisador_cod_aprobacion"
                              label="Código de aprobación"
                              :rules="[ rules.required ]"
                              dense />
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col>
              <v-checkbox v-model="aviso.acepta_condiciones">
                <template #label>
                  <div>
                    Acepto las Condiciones del servicio. <br>
                    <strong>
                      Todos los avisos son publicados a exclusivo cargo del cliente.
                      El Observador no se hace responsable por errores u omisiones en dichos avisos.
                    </strong>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row dense
                 class="mb-6">
            <v-col cols="12"
                   class="text-center">
              <v-alert v-model="submitAlert.visible"
                       :type="submitAlert.type"
                       text
                       max-width="650"
                       class="mx-auto"
                       v-html="submitAlert.texto" />
              <v-btn class="my-3 font-weight-bold"
                     color="primary"
                     x-large
                     :disabled="!aviso.acepta_condiciones || !formValid"
                     :loading="loading"
                     @click="submitAviso">
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { capitalize, cloneDeep } from 'lodash';
  import { DateTime, Settings } from 'luxon';
  import { call, get } from 'vuex-pathify';

  export default {
    name: 'App',

    // import { mdiStarDavid } from '@mdi/js';
    // import { mdiCross } from '@mdi/js';

    data: () => ({
      fechaDecesoMenu: false,
      fechaPublicacionMenu: false,
      generarAlert: {
        visible: false,
        type: 'success',
        texto: '',
      },
      submitAlert: {
        visible: false,
        texto: '',
      },
      aviso: {},
      options: {
        FORMATOS: ['1col', '2col', '3col'],
        FORMAS_PAGO: [
          // { value: 'ABITAB', text: 'Abitab' },
          { value: 'TRANSFERENCIA', text: 'Transferencia bancaria' },
        ],
        SIMBOLOS: [
          { value: 'Cruz', text: 'Cruz', unicode: '\u271D', icon: 'mdi-cross' },
          { value: 'Estrella', text: 'Estrella', unicode: '\u2721', icon: 'mdi-star-david' },
          { value: 'Ninguno', text: 'Sin símbolo', icon: '' },
        ],
        TIPOS_AVISADOR: [
          { value: 'PARTICULAR', text: 'Persona' },
          { value: 'EMPRESA', text: 'Empresa' },
          { value: 'FUNERARIA', text: 'Funeraria' },
        ],
        TIPOS_AVISO: [
          { value: 'Sepelio', text: 'Sepelio' },
          { value: 'Participación', text: 'Participación' },
          // { value: 'Fallecimiento', text: 'Fallecimiento' },
          // { value: 'In Memoriam', text: 'In Memoriam' },
          // { value: 'Misa', text: 'Misa' },
        ],
        DEPARTAMENTOS: ['ARTIGAS', 'CANELONES', 'CERRO LARGO', 'COLONIA', 'DURAZNO', 'FLORES', 'FLORIDA', 'LAVALLEJA',
          'MALDONADO', 'MONTEVIDEO', 'PAYSANDU', 'RIO NEGRO', 'RIVERA', 'ROCHA', 'SALTO', 'SAN JOSE', 'SORIANO',
          'TACUAREMBO', 'TREINTA Y TRES'],
      },
      formValid: true,
      rules: {
        required: v => !!v || 'Este campo es requerido.',
        validarRUT: val => {
          if (val?.length !== 12) {
            return 'El RUT debe tener 12 caracteres de largo.';
          }
          if (!/^([0-9])*$/.test(val)) {
            return 'El RUT debe estar compuesto por números.';
          }
          const dc = +val.substring(11, 12);
          const rut = val.substring(0, 11);
          let total = 0;
          let factor = 2;

          for (let i = 10; i >= 0; i--) {
            total += (factor * rut.substring(i, i + 1));
            factor = (factor === 9) ? 2 : ++factor;
          }
          let dv = 11 - (total % 11);
          if (dv === 11) {
            dv = 0;
          } else if (dv === 10) {
            dv = 1;
          }
          // console.log(`validar RUT: dv=${dv} dc=${dc} rut=${rut} total=${total} factor=${factor}`);
          if (dv === dc) {
            return true;
          }
          return 'Debe ingresar un RUT válido';
        },
      },
    }),

    computed: {
      // aviso: sync('avisos/aviso'),
      loading: get('avisos/pending@aviso'),
      error: get('avisos/error@aviso'),

      cementerios: get('globales/cementerios'),
      velatorios: get('globales/velatorios'),
      precios: get('globales/precios'),
      tamanios: get('globales/tamanios'),

      errorMessage() {
        return this.error?.response?.data || this.error?.request || this.error?.message;
      },

      cementeriosOptions() {
        return this.cementerios.map(e => ({ value: e.id, text: e.nombre }));
      },
      velatoriosOptions() {
        return this.velatorios.map(e => ({ value: e.id, text: e.nombre }));
      },

      fechaPublicacionFormatted() { return this.formatDate(this.aviso.fecha_publicacion); },
      fechaDecesoFormatted() { return this.formatDate(this.aviso.fecha_deceso); },

      tipoAvisador() { return this.aviso.avisador_tipo; },
      idVelatorio() { return this.aviso.id_casa_velatoria; },
      idCementerio() { return this.aviso.id_cementerio; },

      largoAviso() {
        return this.aviso?.aviso?.length || 0;
      },
    },


    watch: {
      tipoAvisador() {
        // se limpian los datos que no corresponden al 'tipo' avisador dado
        switch (this.aviso.avisador_tipo) {
          case 'PARTICULAR':
          case 'EMPRESA':
            this.aviso.avisador_es_funeraria = false;
            this.aviso.avisador_cod_aprobacion = null;
            this.aviso.avisador_nombre = null;
            if (!this.aviso.avisador_forma_pago) this.aviso.avisador_forma_pago = this.options.FORMAS_PAGO[0].value;
            if (!this.aviso.avisador_departamento) this.aviso.avisador_departamento = 'MONTEVIDEO';
            break;
          case 'FUNERARIA':
            this.aviso.avisador_es_funeraria = true;
            this.aviso.avisador_nombre = 'FUNERARIA';
            this.aviso.avisador_documento = null;
            this.aviso.avisador_email = null;
            this.aviso.avisador_telefono = null;
            this.aviso.avisador_forma_pago = null;
            this.aviso.avisador_direccion = null;
            this.aviso.avisador_cod_postal = null;
            this.aviso.avisador_ciudad = null;
            this.aviso.avisador_departamento = null;
            break;
        }
      },
      idVelatorio(val) {
        const velatorio = this.velatorios.find(v => v.id === val);
        if (velatorio) {
          this.aviso.casa_velatoria = velatorio.nombre;
          this.aviso.casa_velatoria_aviso = velatorio.nombre_aviso || velatorio.nombre;
          this.aviso.casa_velatoria_direccion = velatorio?.direccion;
        }
      },
      idCementerio(val) {
        this.aviso.cementerio = this.cementerios.find(c => c.id === val)?.nombre;
      },
      largoAviso(val) {
        if (this.tamanios && this.precios) {
          this.aviso.formato = val <= this.tamanios['1col'] ? '1col' : (val <= this.tamanios['2col'] ? '2col' : '3col');
          this.aviso.precio = this.precios[this.aviso.formato];
        }
      },
    },

    created() {
      Settings.defaultZone = 'America/Montevideo';
      Settings.defaultLocale = 'es-uy';
      this.aviso = this.nuevoAviso();
      this.loadCementerios({})
          .then(() => (this.aviso.id_cementerio = this.cementerios[0]?.id));
      this.loadVelatorios({})
          .then(() => (this.aviso.id_casa_velatoria = this.velatorios[0]?.id));
      this.loadPrecios({})
          .then(() => (this.aviso.precio = this.precios['1col']));
      this.loadTamanios({});
    },

    mounted() {
    },

    methods: {
      loadCementerios: call('globales/getCementerios'),
      loadVelatorios: call('globales/getVelatorios'),
      loadPrecios: call('globales/getPrecios'),
      loadTamanios: call('globales/getTamanios'),
      postAviso: call('avisos/postAviso'),

      nuevoAviso() {
        const velatorio = (this.velatorios || [])[0];
        const cementerio = (this.cementerios || [])[0];
        const ahora = DateTime.now();
        return {
          fecha_publicacion: DateTime.now().plus({
              // days: DateTime.now().get('hour') >= 21 ? 2 : 1
              days: (ahora.get('hour') >= 17 || (ahora.get('hour') === 16 && ahora.get('minute') >= 30)) ? 2 : 1,
          }).toISODate(),
          fecha_deceso: DateTime.now().toISODate(),
          tipo_aviso: this.options.TIPOS_AVISO[1].value,
          simbolo: this.options.SIMBOLOS[0].value,
          nombre_difunto: null,
          id_casa_velatoria: velatorio?.id || null,
          casa_velatoria: velatorio?.nombre || null,
          casa_velatoria_aviso: velatorio?.nombre_aviso || velatorio?.nombre || null,
          casa_velatoria_direccion: velatorio?.direccion || null,
          id_cementerio: cementerio?.id || null,
          cementerio: cementerio?.nombre || null,
          formato: '1col',
          precio: this.precios?.['1col'] || 0,
          aviso: null,
          // - datos anunciante
          avisador_tipo: this.options.TIPOS_AVISADOR[0].value,
          avisador_es_funeraria: null,
          avisador_nombre: null,
          avisador_email: null,
          // -- solo funerarias:
          avisador_cod_aprobacion: null,
          // -- solo particular/empresa:
          avisador_documento: null,
          avisador_telefono: null,
          avisador_forma_pago: this.options.FORMAS_PAGO[0].value,
          avisador_direccion: null,
          avisador_cod_postal: null,
          avisador_ciudad: null,
          avisador_departamento: 'MONTEVIDEO',
          acepta_condiciones: false,
        };
      },

      generarTextoSugerido() {
        this.generarAlert.visible = false;
        if (!this.aviso.simbolo) {
          this.generarAlert.texto = 'Debe seleccionar el tipo de aviso.';
          this.generarAlert.visible = true;
          return;
        }

        let txt = 'Falleció ';
        if (this.aviso.simbolo !== 'Estrella') {
          txt = '(Q.E.P.D.) - ' + txt;
        }
        txt += (this.aviso.simbolo === 'Cruz' ? 'en la paz del Señor confortado con los santos sacramentos y la bendición papal, '
          : (this.aviso.simbolo === 'Ninguno' ? 'en paz ' : '')) + `el día ${this.fechaDecesoFormatted}. `;

        txt += (this.aviso.deudos ? `${this.aviso.deudos} y demás f` : 'F') +
               'amiliares y amigos participan con profundo dolor dicho fallecimiento ';

        if (this.aviso.tipo_aviso === 'Sepelio') {
          txt += 'e invitan al acto de sepelio a efectuarse en el día de hoy. ' +
                 `Casa velatoria: ${this.aviso.casa_velatoria_direccion}`;

          // txt += `Casa velatoria: ${this.aviso.casa_velatoria_direccion}, sala ${this.aviso.sala_velatoria}, a partir de la hora ${this.aviso.hora_velatorio}. El cortejo parte a la hora ${this.aviso.hora_partida} hacia el ${this.aviso.cementerio}.`;

          if (this.aviso.sala_velatoria) {
            txt += `, sala ${this.aviso.sala_velatoria}`;
          }
          if (this.aviso.hora_velatorio) {
            txt += `, a partir de la hora ${this.aviso.hora_velatorio}`;
          }
          if (this.aviso.hora_partida) {
            txt += `. El cortejo parte a la hora ${this.aviso.hora_partida} hacia el ${this.aviso.cementerio}.`;
          } else {
            txt += `. El cortejo parte luego hacia el ${this.aviso.cementerio}.`;
          }
        } else if (this.aviso.tipo_aviso === 'Participación') {
          txt += `y su sepelio efectuado ayer en el ${this.aviso.cementerio}.`;
        }
        txt += ` (${this.aviso.casa_velatoria_aviso})`;

        this.aviso.aviso = txt;
      },

      submitAviso() {
        this.submitAlert.visible = false;
        const avisoAPublicar = cloneDeep(this.aviso);

        if (!avisoAPublicar.aviso) {
          this.notify('error', 'El aviso no tiene texto.');
          return;
        }
        this.postAviso({ data: avisoAPublicar })
            .then(res => {
              this.submitAlert.texto = `Aviso enviado correctamente.<br>El código identificador de su aviso es: <strong>FUN${res.data.avisoID}</strong>`;
              this.submitAlert.type = 'success';
              this.submitAlert.visible = true;
              // this.notify('success', `Aviso enviado correctamente.<br>ID: ${res.data.id}`);
              this.aviso = this.nuevoAviso();
              this.$refs.avisoForm.resetValidation();
            })
            .catch(e => {
              if (e.response) {
                this.submitAlert.texto = this.errorMessage;
              } else {
                // eslint-disable-next-line no-console
                console.error(this.errorMessage);
                this.submitAlert.texto = 'Hubo un error enviando el aviso, por favor inténtelo nuevamente. <br>' +
                                         'Si necesitas ayuda puedes comunicarte al 2924 7000 o escribirnos a ' +
                                         '<a href="mailto:funebres@observador.com.uy" target="_blank" style="color: #2BAADF;">funebres@observador.com.uy</a>.';
              }
              this.submitAlert.type = 'error';
              this.submitAlert.visible = true;
            });
      },

      fechaHabilitada: val => {
        const fecha = DateTime.fromISO(val);
        const ahora = DateTime.now();
        return fecha >= ahora.plus({
            days: ahora.get('hour') >= 17 || (ahora.get('hour') === 16 && ahora.get('minute') >= 30) ? 2 : 1,
        }).startOf('day');
      },

      capitalize(text) {
        return text.toLowerCase().replace(/\p{Letter}+/gu, capitalize);
      },

      notify(type, message, timeout = 5000) {
        this.$dialog.notify[type](message, { position: 'top-right', timeout });
      },

      formatDate(date) {
        return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
      },

      formatPrecio(precio) {
        return new Intl.NumberFormat('es-UY', { style: 'currency', currency: 'UYU', maximumFractionDigits: 0 })
          .format(precio);
      },
    },
  };
</script>

<style scoped lang="scss">
.main {
  max-width: 850px;

  .hr-dotted {
    border-style: dotted;
  }

}
</style>

<style lang="scss">
.main .legend-top legend {
  width: 100%;
}
</style>
