import { Settings } from 'luxon';
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vuetify-dialog';

Vue.config.productionTip = false;

Settings.defaultZone = 'America/Montevideo';
Settings.defaultLocale = 'es-uy';

new Vue({
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
